import axios from "axios"

const instance = axios.create({
	baseURL: import.meta.env.VITE_BASE_API_URL + "/",
	headers: {
		"X-Requested-With": "XMLHttpRequest",
		"x-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
	},
	withCredentials: true,
})

export default instance
