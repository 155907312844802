export default async function loadZiggy(app) {
	let route = "/api/ziggy"
	if (import.meta.env.VITE_TEST_MODE) {
		route = import.meta.env.VITE_BASE_API_URL + "/ziggy"
	}

	const response = await fetch(route)
		.then((data) => data.json())
		.then((data) => {
			window.Ziggy = data
			app.use(Ziggy)
		})
}
