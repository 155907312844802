var autoUpdateMode = "true"
var selfDestroying = "false"
var auto = autoUpdateMode === "true"
var autoDestroy = selfDestroying === "true"
function registerSW(options = {}) {
	const {
		immediate = false,
		onNeedRefresh,
		onOfflineReady,
		onRegistered,
		onRegisteredSW,
		onRegisterError,
		filename = "sw.js",
	} = options
	let wb
	let registerPromise
	let sendSkipWaitingMessage
	const updateServiceWorker = async (_reloadPage = true) => {
		await registerPromise
		if (!auto) {
			await sendSkipWaitingMessage?.()
		}
	}
	async function register() {
		if ("serviceWorker" in navigator) {
			let newFilename = filename
			if (!filename.startsWith("/")) {
				newFilename = "/" + newFilename
			}
			const { Workbox } = await import("workbox-window")
			wb = new Workbox(newFilename, { scope: "/", type: "classic" })
			sendSkipWaitingMessage = async () => {
				await wb?.messageSkipWaiting()
			}
			if (!autoDestroy) {
				if (auto) {
					wb.addEventListener("activated", (event) => {
						if (event.isUpdate || event.isExternal) window.location.reload()
					})
					wb.addEventListener("installed", (event) => {
						if (!event.isUpdate) {
							onOfflineReady?.()
						}
					})
				} else {
					let onNeedRefreshCalled = false
					const showSkipWaitingPrompt = () => {
						onNeedRefreshCalled = true
						wb?.addEventListener("controlling", (event) => {
							if (event.isUpdate) window.location.reload()
						})
						onNeedRefresh?.()
					}
					wb.addEventListener("installed", (event) => {
						if (typeof event.isUpdate === "undefined") {
							if (typeof event.isExternal !== "undefined") {
								if (event.isExternal) showSkipWaitingPrompt()
								else !onNeedRefreshCalled && onOfflineReady?.()
							} else {
								if (event.isExternal) window.location.reload()
								else !onNeedRefreshCalled && onOfflineReady?.()
							}
						} else if (!event.isUpdate) {
							onOfflineReady?.()
						}
					})
					wb.addEventListener("waiting", showSkipWaitingPrompt)
					wb.addEventListener("externalwaiting", showSkipWaitingPrompt)
				}
			}
			wb.register({ immediate })
				.then((r) => {
					if (onRegisteredSW) onRegisteredSW(newFilename, r)
					else onRegistered?.(r)
				})
				.catch((e) => {
					onRegisterError?.(e)
				})
		}
	}
	registerPromise = register()
	return updateServiceWorker
}
export { registerSW }
