/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import http from "@/Services/http"

window.http = http

const initVars = JSON.parse(atob(window.efboot))

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from "laravel-echo"

import Pusher from "pusher-js"
window.Pusher = Pusher

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
//     wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });

window.Echo = new Echo({
	broadcaster: "pusher",
	key: initVars["pusher_app_key"],
	cluster: initVars["pusher_app_cluster"],
	forceTLS: true,
})

import { datadogRum } from "@datadog/browser-rum"

if (initVars["rum_enabled"]) {
	datadogRum.init({
		applicationId: initVars["rum_app_id"],
		clientToken: initVars["rum_client_token"],
		site: "datadoghq.com",
		service: "efz-admin",
		env: initVars["app_env"],
		// Specify a version number to identify the deployed version of your application in Datadog
		version: initVars["app_version"],
		sessionSampleRate: 100,
		sessionReplaySampleRate: 20,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: "mask-user-input",
	})
	datadogRum.startSessionReplayRecording()
}
